import { Avatar } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { getToken, logout } from "service/helpers/utilities";
import logo from "assets/images/meradoc.png";
import user from "assets/images/menOne.jpg";
import bell from "assets/icons/bell.png";
import "component/common/Header/header.scss";
import search from "assets/icons/search.svg";
import FooterComp from "../FooterComp";
import { NavDropdown, Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import { history, getFirstLetter } from "helpers";
import { Divider } from "antd";
import { getHeaderUserDetail } from "service/MyProfile";
import MenuIcon from "@material-ui/icons/Menu";
import NavBar from "./NavBar";
import NormalButton from "../NormalButton/NormalButton";
import { getUserProfile } from "redux/action/home";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTenantById } from "service/miscellaneous";
import { useHistory, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFEFE",
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
}));
const Header = () => {
  const { header } = useStyles();
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();
  const [userData, setuserData] = useState({});
  const param = useParams();
  const userProfile = useSelector((state) => state?.home?.userData);
  const [tanentData, settanentData] = useState({})

  let headerArray = [];
  console.log(userProfile, "userProfile")


  if (window.location.href.includes("auth/register/fkh")) {
    headerArray = [
      {
        label: "Consult Specialist",
        to: "/book-specialist",
        isHide: false,
      },
      {
        label: "Offers",
        to: "/offers",
        isHide: false,
      },
      {
        label: "Surgery Care",
        to: "/surgery-care",
        isHide: false,
      },
      {
        label: "Genomic Wellness",
        to: "/genomic-wellness",
        isHide: false,
      }
      // {
      //   label: "Corporate ",
      //   to: `/MeraDoc/corporate?contactType=CORPORATE`,
      //   isHide: false,
      // },
      // {
      //   label: "About Us",
      //   to: "/MeraDoc/about-us-page",
      //   isHide: false,
      // },
      // {
      //   label: "Wellness",
      //   to: "/MeraDoc/wellness?contactType=WELLNESS",
      // },
    ];
  } else {
    headerArray = [
      {
        label: "Consult Specialist",
        to: "/book-specialist",
        isHide: false,
      },
      {
        label: "Offers",
        to: "/offers",
        isHide: false,
      },
      {
        label: "Surgery Care",
        to: "/surgery-care",
        isHide: false,
      },
      {
        label: "Hospitals",
        to: "/hospitals",
        isHide: false,
      },
      {
        label: "Medicine",
        // to:'search-medicine',
        to: "/medicine-choice",
        isHide: userProfile?.isHideMedicine ? true : false,
      },

      {
        label: "Diagnostics",
        // to: "/diagnostics",
        to: "/diagnostic-choice",
        isHide: userProfile?.isHideDiagnostic ? true : false,
      },
      // {
      //   label: "Corporate ",
      //   to: `/MeraDoc/corporate?contactType=CORPORATE`,
      //   isHide: false,
      // },
      // {
      //   label: "About Us",
      //   to: "/MeraDoc/about-us-page",
      //   isHide: false,
      // },
      // {
      //   label: "Wellness",
      //   to: "/MeraDoc/wellness?contactType=WELLNESS",
      // },
    ];
  }

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetch = () => {
      if (getToken() !== null) {
        dispatch(getUserProfile());
      }
    };

    fetch();
  }, [getToken()]);


  const tData = async () => {
    let tanentDataFetch = await getTenantById(userProfile.createdById)

    console.log(tanentDataFetch, "tanentDataFetch")

    if (tanentDataFetch.status == 200) {
      settanentData(tanentDataFetch.data?.data)
    }
  }

  useEffect(() => {
    getUserHeaderData();
  }, []);


  useEffect(() => {
    if (userProfile?.createdById?.length > 0) {
      tData()
    }
  }, [userProfile?.createdById])


  console.log(tanentData, "tanentData")

  const getUserHeaderData = async () => {
    try {
      setIsLoad(true);
      if (getToken() !== null) {
        let response = await getHeaderUserDetail();
        if (response) {
          if (response.data.data.status) {
            setuserData(response?.data?.data);
            setIsLoad(false);
          } else {
            localStorage.clear();
            history.push("/");
          }
        }
      }
    } catch (err) {
      let errors = err?.response?.data?.status;
      if (errors == 400) {
        localStorage.clear();
        history.push("/");
      }
    }
  };

  const carePlusCheck = () => {
    if (userProfile?.subscriptionData?.status == "ACTIVE") {

      return <div className='d-flex align-items-center care_crown '>
        <span className='carePlusText-profile mr-1' style={{ display: "block", color: "#F7931A", fontSize: "9px", fontStyle: "italic" }}> Care Plus Member</span>
        <img style={{ width: "20px" }} src='https://meradoc-pre-prod.s3.ap-south-1.amazonaws.com/pdf/1716368092149-27e67344e08a-crown_Image.png'></img>

      </div >

    }
    else {
      return ""
    }
  }

  const displayDesktop = () => {


    return (
      <Toolbar>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="header-logo">
            {localStorage.getItem("token") && <MenuIcon onClick={toggle} />}
            <img
              src={logo}
              onClick={() => history.push("/")}
              className="cursor-pointer"
              alt="img_logo"
            />
          </div>
          <div className="d-flex align-items-center">
            <div className="d-lg-flex d-md-none d-none">
              {headerArray.map((list, index) => (
                <div
                  // className="headerText"
                  key={index}
                  className={
                    list.label !== "Offers" &&
                      list.label !== "Surgery Care" &&
                      list.label !== "Senior Care"
                      ? `headerText ${list?.isHide && "d-none"}`
                      : `headerText`
                  }
                  onClick={() => history.push(list.to)}
                >
                  {list.label}
                  {/* {list.label}{" "} */}
                  {/* {(index == 1 || index == 2 || index == 3) && (
                    <span className="newName ml-2 blink_offer">new</span>
                  )} */}
                </div>
              ))}
            </div>
            {localStorage.getItem("token") !== null ? (
              <div className="d-flex dropDown align-items-center">
                {userProfile?.profileUrl && (
                  <Avatar src={userProfile?.profileUrl} className="ml-2" />
                )}

                {!userProfile?.profileUrl && (
                  <div>

                    <Avatar className="ml-2" style={{ backgroundColor: "#636363" }}>
                      {getFirstLetter(userProfile?.firstName?.split(" "))}
                    </Avatar>
                  </div>
                )}

                <NavDropdown title={<div>
                  {carePlusCheck()}
                  {userProfile?.firstName}
                </div>}>
                  <NavDropdown.Item href="/patient/my-profile">
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/patient/consultations">
                    Consultations
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/patient/settings">
                    Settings
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/patient/faq">FAQ</NavDropdown.Item>
                  <Divider className="my-0" />
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                <div>

                  {tanentData?.ssoPartnerLogo && <img className="partnerLogo" src={tanentData.ssoPartnerLogo} />}
                </div>
              </div>
            ) : (
              <div className="d-none d-md-block">
                <NormalButton
                  label={"Login / Register"}
                  registerLoginBtn
                  onClick={() => history.push("/auth/login")}
                />
              </div>
            )}
          </div>
        </div>
      </Toolbar>
    );
  };
  const femmecubatorLogo = (
    <Typography variant="h6" component="h1">
      Femmecubator
    </Typography>
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  let hideNavbarArray = ['/auth/login']
  const params = useHistory()
  console.log('params', params.location.pathname)


  return (
    <Fragment>
      {!(hideNavbarArray.indexOf(params.location.pathname) > -1) ? <>
        <NavBar isOpen={isOpen} toggle={toggle} />
        <div className="header">
          <header>
            <AppBar style={{ boxShadow: "none", backgroundColor: "white" }}>
              {displayDesktop()}
            </AppBar>
          </header>
        </div>
      </> : <></>}
    </Fragment>
  );
};
export default Header;
