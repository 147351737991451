// DEVELOPMENT

// export const baseURL = "http://doodlebluelive.com";
// export const authURL = `${baseURL}:2055/api/v1`;
// export const profileURL = `${baseURL}:2057/api/v1`;
// export const consultationURL = `${baseURL}:2056/api/v1`;
// export const faqURL = `${baseURL}:2078/api/v1`;
// export const blogURL = `${baseURL}:2078/api/v1`;
// export const testimonialURL = `${baseURL}:2078/api/v1`;
// export const diagnosticsURL = `${baseURL}:2057/api/v1`;
// export const medicineURL = `${baseURL}:2057/api/v1`;
// export const fkhSubscription = "6469c7611b2bc0656c9fba6f";  //dev

//PRE PRODUCTION
// export const razorPayKey = "rzp_test_UmmHfd3otJYTU9";

// export const baseURL = "https://apidev.meradoc.com";
// export const baseURL = "http://localhost:8000";


export const wearable_callback_url_preprod =
  "https://patientdev.meradoc.com/patient/my-profile";
// export const baseURL = "https://api-preprod.meradoc.com";

// export const authURL = `${baseURL}/user/api/v1`;
// export const profileURL = `${baseURL}/prescription/api/v1`;
// export const consultationURL = `${baseURL}/appointment/api/v1`;
// export const faqURL = `${baseURL}/cms/api/v1`;
// export const blogURL = `${baseURL}/cms/api/v1`;
// export const testimonialURL = `${baseURL}/cms/api/v1`;
// export const diagnosticsURL = `${baseURL}/prescription/api/v1`;
// export const medicineURL = `${baseURL}/prescription/api/v1`;
// export const medPay = `${baseURL}/pharmacy/api/v1`;
// export const wearables = `${baseURL}/wearables/api/v1`;
// <<<<<<<<< Temporary merge branch 1
// export const fkhSubscription = "6274bddad859e50012557f21";
// =========
// export const fkhSubscription = "6274bddad859e50012557f21";  
// >>>>>>>>> Temporary merge branch 2
// export const msVideoCallMainUrl = `meradoc-preprod-videoconf-2056`;//pre-prod

// PRODUCTION
// export const razorPayKey = "rzp_live_DkT1kmU4mT8Dod";

// export const baseURL = "https://api.meradoc.com";
// export const authURL = `${baseURL}/user/api/v1`;
// export const profileURL = `${baseURL}/prescription/api/v1`;
// export const consultationURL = `${baseURL}/appointment/api/v1`;
// export const faqURL = `${baseURL}/cms/api/v1`;
// export const blogURL = `${baseURL}/cms/api/v1`;
// export const testimonialURL = `${baseURL}/cms/api/v1`;
// export const diagnosticsURL = `${baseURL}/prescription/api/v1`;
// export const medicineURL = `${baseURL}/prescription/api/v1`;
// export const medPay = `${baseURL}/pharmacy/api/v1`;
// export const wearables = `${baseURL}/wearables/api/v1`;
// export const fkhSubscription = "6274bddad859e50012557f21";
// export const msVideoCallMainUrl = `meradoc-preprod-videoconf-2056`;//pre-prod

// PRODUCTION
export const razorPayKey = "rzp_live_DkT1kmU4mT8Dod";

export const baseURL = "https://api.meradoc.com";
export const authURL = `${baseURL}/user/api/v1`;
export const profileURL = `${baseURL}/prescription/api/v1`;
export const consultationURL = `${baseURL}/appointment/api/v1`;
export const faqURL = `${baseURL}/cms/api/v1`;
export const blogURL = `${baseURL}/cms/api/v1`;
export const testimonialURL = `${baseURL}/cms/api/v1`;
export const diagnosticsURL = `${baseURL}/prescription/api/v1`;
export const medicineURL = `${baseURL}/prescription/api/v1`;
export const medPay = `${baseURL}/pharmacy/api/v1`;
export const wearables = `${baseURL}/wearables/api/v1`;
export const fkhSubscription = "62873ded59eb1a0012dc111f";
export const msVideoCallMainUrl = `meradoc-prod-videoconf-1518`;

export const endpoints = {
  auth: {
    SEND_OTP: `${authURL}/user/sendOtp`,
    SEND_EMAIL_OTP: `${authURL}/user/sendEmailOtp`,
    REGISTER_USER: `${authURL}/user/verifyOtp`,
    CREATE_PASSWORD: `${authURL}/user/createPassword`,
    PASSWORD_SIGNIN: `${authURL}/user/signIn`,
    GET_MULTIACCOUNT: `${authURL}/user/signIn/patient/byMobile`,
    // CHANGE_PASSWORD: `${authURL}/user/changePassword`
    CHANGE_PASSWORD: `${authURL}/user/changePassword`,
    CONTACT_US: `${authURL}/user/contactUs`,
    FORGET_PASSWORD: `${authURL}/user/forgetPassword`,
    CORPORATE_SLUG: `${authURL}/admin/corporate/bySlug`,
    SSO_VALIDATE: `${authURL}/sso/validate`,
    GET_TENANT_BY_ID:`${authURL}/sso/tanent`,
    LOGOUT: `${authURL}/user/logout`,
  },
  myProfile: {
    SEND_OTP_PHONE: `${authURL}/user/mobileNumberVerify/sendOtp`,
    REGISTER_USER_PHONE: `${authURL}/user/mobileNumberVerify/verifyOtp`,
    GET_MY_PROFILE: `${authURL}/user/userProfile`,
    EDIT_MY_PROFILE: `${authURL}/user/editProfile`,
    GET_LAB_REPORTS: `${profileURL}/document/documents`,
    GET_MEDICINES: `${profileURL}/pharmacy/myOrder/medicineList`,
    GET_DIAGNOSTIC_REPORTS: `${profileURL}/diagonostic/diagonosticList`,
    GET_ORDERED_DIAGONISES: `${diagnosticsURL}/diagonostic/myOrder/diagonosticList`,
    // /prescription/api/v1/diagonostic/myOrder/diagonosticList?page=1&size=10
    UPLOAD_IMAGES: `${profileURL}/prescription/upload`,
    REPORT_UPLOAD: `${profileURL}/document/document/bulk`,
    UPDATE_MEDICAL_HISTORY: `${authURL}/user/createMedicalHistory`,
    GET_FAMILY_MEMBERS: `${authURL}/user/getFamilyMember`,
    HEADER_USER_DETAIL: `${authURL}/user/userIcon`,
    PROFILE_COMPLETION_PERCENT: `${authURL}/user/profileCompletion`,
    GET_ALL_PRESCRIPTION: `${profileURL}/prescription/prescriptionData`,
    ADD_FAMILY_MEMBER: `${authURL}/user/addFamilyMember`,
    GET_FAMILY_TREE: `${authURL}/user/getFamilyTree`,
    GET_FAMILY_TREE_WEB_VIEW: `${authURL}/user/getFamilyTreeForWebView`,
    GET_FAMILY_MEMBER_BY_COND: `${authURL}/user/getFamilyMemberByCond`,
    UPDATE_FAMILY_MEMBER: `${authURL}/user/familyMember`,
    DELETE_FAMILY_MEMBER: `${authURL}/user/familyMember`,
    GET_MEDICAL_HISTORY: `${authURL}/user/getMedicalHistory`,
    GET_FAMILY_RELATIONS: `${authURL}/user/family/relations`,
    DELETE_PRESCRIPTION_IMAGE: `${profileURL}/document/documents`,
    GET_NEXT_DOCTOR_SLOT: `${consultationURL}/appointment/nextSlot`,
    GET_SUBCRIPTION: `${authURL}/user/subscription/list`,
    GET_ALL_SUBCRIPTION: `${authURL}/user/subscription/getAll`,
    GET_MOBILENUMBER_MEMBER: `${authURL}/user/patient/byMobile`,
    GET_BY_PINCODE: `${authURL}/user/searchPincode`,
    webinar: `${authURL}/user/webinar`,
    webinarDetails: `${authURL}/user/webinar/data`,
    DEACTIVETE_PROFILE:`${authURL}/user/admin/changeActiveStatus`
    
    
  },
  consultation: {
    GET_ALL_CONSULTATION: `${consultationURL}/appointment/appointments`,
    GET_CONSULTATION_INFORMATION: `${consultationURL}/appointment/appointment`,
    GET_GP_SLOTS: `${consultationURL}/slots/available/general`,
    GET_SP_SLOTS: `${consultationURL}/slots/available/specialist`,
    GET_DATA_BY_SLOTID: `${consultationURL}/appointment/preBookingDisplay`,
    CREATE_GP_APPOINTMENT: `${consultationURL}/appointment/appointment-create`,
    GET_ALL_SPECIALIST: `${consultationURL}/specialty/list`,
    GET_ALL_SPECIALIST_SERVICE: `${consultationURL}/specialty/service`,
    GET_SPECIALIST_BY_ID: `${authURL}/doctor/findDoctor/speciality`,
    CANCEL_APPOINTMENT: `${consultationURL}/appointment/appointment-cancel`,
    RESCHEDULE_APPOINTMENT: `${consultationURL}/appointment/appointment-update`,
    CANCEL_PAYMENT: `${consultationURL}/slots/activate`,
    GET_PATIENT_INVOICE: `${consultationURL}/appointment/invoiceInfo`,
    GET_CONSULTATION_BY_COND: `${consultationURL}/appointment/getAppointmentByCond`,
    UPDATE_CONSULTATION_DOCUMENT: `${consultationURL}/appointment/appointment-update-documents`,
    UPDATE_MEMBER_ADDED: `${consultationURL}/appointment/updateAppointment`,
    GET_SPECIALIST_LIST: `${authURL}/doctor/listSpecialty`,
    GET_NEXT_AVAILABLE_DOCTOR: `${consultationURL}/slots/next/availableDoctor`,
    GET_NEXT_RECENT_AVAILABLE_DOCTOR: `${consultationURL}/appointment/recentConsultedDoctors`,
    GET_NEXT_DOCTOR_DATE_AVAILABLE: `${consultationURL}/slots/available/date`,
  },
  faq: {
    GET_FAQ: `${faqURL}/faq`,
    GET_TEARMS_AND_CONDITION: `${faqURL}/cms/policy/byType`,
    // GET_SUBCRIPTION_PLAN: `${faqURL}/admin/subscription`,
    GET_SUBCRIPTION_PLAN: `${authURL}/admin/subscription`,
    PURCHASE_SUBCRIPTION: `${authURL}/user/subscription/purchase`,
    GET_SUBCRIPTION_INVOICE: `${authURL}/user/subscription/invoiceInfo`,
    ADD_SUBCRIPTION_COUPON: `${authURL}/user/subscription/purchase/summary`,
    ADD_PURCHASE_COUPON: `${authURL}/user/offer/page/create`,
    ADD_FLIPKART_SUBSCRIPTION_COUPON: `${authURL}/user/subscription/purchase/summary/flipkart`,
    GET_FKH_SUBSCRIPTION: `${authURL}/admin/subscription/flipkart`,
  },
  paymentInstance:{
    generatePaymentInstance:`${authURL}/user/make/paymentInstance`
  },

  offer: {
    ADD_OFFER_COUPON: `${authURL}/user/offer/page/create`,
    CHECK_MEDICAL_HISTORY: `${authURL}/user/medicalHistory/check`,
    GET_MY_OFFER: `${profileURL}/diagonostic/offer`,
  },

  blogs: {
    GET_BLOGS: `${blogURL}/blog`,
    GET_BLOGS_URL: `${blogURL}/blog/blogurl`,
    GET_ALL_CATEGORY: `${blogURL}/blog/category/list`,
    GET_BLOG_SEQUENCE: `${blogURL}/blog/getBlogBysequence`,
    GET_CATEGORY_ARTICLE: `${blogURL}/blog/byCategory`,
    POST_QUESTION: `${blogURL}/forum/createQuestionByPatient`,
  },
  doctor: {
    GET_DOCTOR_DETAIL: `${authURL}/doctor/getDoctorCompleteDetails`,
    LIST_ALLSLOTS_BY_DOCTOR: `${consultationURL}/slots/listAllDoctorSlots`,
    GET_DOCTOR_LANGUAGE: `${authURL}/doctor/language/list`,
  },
  career: {
    GET_ALL_JOB_LIST: `${blogURL}/jobCreation/jobs/list`,
    APPLY_JOB_APPLICATION: `${blogURL}/jobRequest/career/create`,
    UPLOAD_RESUME_FILE: `${medicineURL}/prescription/upload/pdf`,
  },
  diagnostics: {
    GET_DAIAGNOSTICS_DETAIL: `${authURL}/doctor/getDoctorCompleteDetails`,
    GET_ALL_DIAGNOSTICS_LIST: `${diagnosticsURL}/diagonostic/diagonosticList`,
    UPLOAD_DIAGNOSTICS: `${profileURL}/diagonostic/bookingdiagonostic`,
    LAB_SEARCH:`${medPay}/medpay/labsearch`,
    //new diagnostics flow
    GET_PARTNER_TEST: `${medPay}/diagnostic/tests`,
    GET_ALL_CITIES_LIST: `${medPay}/diagnostic/cities`,
    GET_TEST_BASED_CITY: `${medPay}/diagnostic/tests/city`,
    GET_DIAGNOSTIC_SLOTS: `${medPay}/diagnostic/slot/availability`,
    BOOK_DIAGNOSTIC_SLOTS: `${medPay}/diagnostic/order`,
    DIAGNOSTIC_ADD_CART: `${medPay}/diagnostic/cart`,
    GET_DIAGNOSTIC_FAMILY_MEMBERS: `${authURL}/user/diagnostics/getFamilyMember`,
    DIAGNOSTIC_ORDER: `${medPay}/diagnostic/order`,
    CANCEL_DIAGNOSTIC_ORDER: `${medPay}/diagnostic/order/cancel`,
    GET_BRANCH_DAIGNOSIS: `${medPay}/diagnostic/centers/list`,
    GET_SEARCH_PARTNER_TEST: `${medPay}/diagnostic/tests/search`,
    RESCHEDULE_DIAGNOSIS_SLOT: `${medPay}/diagnostic/order/update`,
    GET_REDCLIFF_DIAGNOSTIC_SLOTS: `${medPay}/diagnostic/slot-redcliffe/availability`,
    GET_ALL_PRODUCT_PREFERRED_OPTION: `${medPay}/diagnostic/checkSimilar`,
    DIAGNOSTIC_ADD_CART_REPLACE: `${medPay}/diagnostic/cart/replace`,
    ORANG_HEALTH_FETCH_SERVICEABILITY:`${medPay}/diagnostic/slot-orangeHealth/availability`,
    CREATE_BOOKING_AGILUS:`${medPay}/diagnostic/slot-orangeHealth/availability`,
    GET_SLOT_AGILUS:`${medPay}/diagnostic/slot-agilus/availability`,
    CANCEL_ORDER_AGILUS:`${medPay}/diagnostic//agilus/cancel_reasons`,
    CHECK_ORDER_STATUS:`${medPay}/diagnostic/checkStatus_orangeHealth`
  },
  medicine: {
    
    GET_ALL_MEDICINE_LIST: `${medicineURL}/pharmacy/pharmacyList`,
    UPLOAD_MEDICINE: `${profileURL}/pharmacy/bookingMedicine`,
    ADD_NEW_ADDRESS: `${authURL}/user/address/create`,
    GET_CART_ADDRESS: `${authURL}/user/address/view`,
    GET_ALL_MEDICINE_ADDRESS: `${authURL}/user/address/list`,
    DELETE_ADDRESS_MEDICINE: `${authURL}/user/address/delete`,
    UPDATE_ADDRESS_MEDICINE: `${authURL}/user/address/update`,
    GET_ALL_CART: `${medPay}/cart`,
    GET_CART_COUNT: `${medPay}/cart/count`,
    GET_SEARCH_MEDICINE: `${medPay}/medpay/searchMedicine`,
    GET_SEARCH_LAB_TEST:`${medPay}/medpay/uniqueLabTestSearch`,
    GET_SELECTED_PRODUCT_DETAIL: `${medPay}/medpay/medicine`,
    ADD_MEDICINE_TO_CART: `${medPay}/cart`,
    GET_PHARMACY_COUPON: `${consultationURL}/coupon/listPharmacy`,
    CREATE_OREDR: `${medPay}/orders`,
    GET_STORES:`${authURL}/admin/getAllStores`,
    GET_NLABS:`${medPay}/medpay/getLabs`,
    GET_MOST_ORDERED: `${medPay}/orders/most-ordered`,
    CANCEL_ORDER: `${medPay}/orders/cancel`,
    APPROVE_OREDR: `${medPay}/orders/confirm`,
    GET_UPLOADED_PRESCRIPITON: `${medPay}/cart/prescription`,
    ADDITIONAL_DISCOUNT_MAIL: `${medPay}/cart/discount/request`,
    SAVE_INBOUND_DATA:`${profileURL}`,
    GET_HOSPITALS:`${medPay}/medpay/hospitals` ,
    GET_HOSPITAL_BY_ID:`${medPay}/medpay/hospital` ,
    GET_SUGGESTION:`${medPay}/medpay/hospital/suggestion` ,

    

  },
  testimonial: {
    GET_ALL_TESTIMONIAL_LIST: `${testimonialURL}/cms/testimonial`,
  },
  wearable: {
    GET_TOKEN_WEARABLES: `${wearables}/authentication/token`,
    GET_CONNECTED_WEARABLES: `${wearables}/authentication/connected/apps`,
    GET_ACTIVITY_DISTANCE: `${wearables}/health/activity/distance/chart`,
    GET_SLEEP_DATA_PROFILE: `${wearables}/health/profile/sleep`,
    GET_SLEEP_DATA: `${wearables}/health/sleep`,
    GET_CHART_HEART_RATE: `${wearables}/health/heart/chart`,
    GET_BLOOD_GLUCOSE_CHART: `${wearables}/health/bloodGlucose/chart`,
    GET_BLOOD_GLUCOSE_DATA: `${wearables}/health/profile/bloodGlucose`,
    GET_ACTIVITY_DURATION_CHART: `${wearables}/health/activity/duration/chart`,
    GET_ACTIVITY_CALORY_CHART: `${wearables}/health/activity/calories/chart`,
    GET_ACTIVITY_STEPS_CHART: `${wearables}/health/activity/steps/chart`,
    GET_HEART_RATE: `${wearables}/health/heart`,
    GET_ACTIVITY: `${wearables}/health/activity`,
    GET_PROFILE_HEART_ACTIVITY: `${wearables}/health/profile/heart/activity`,
  },
  marketing: {
    // SEND_DATA: `${marketingURL}/marketing/testMarket`
  }
  ,
  abha: {
    otherDocSendOtpApiEndPoint: `${baseURL}/user/api/v1/abha/M1/registration/otherDocument/sentMobileOtp`,
    otherDocVerifyOtpApiEndPoint: `${baseURL}/user/api/v1/abha/M1/registration/otherDocument/verifyMobileOtp`,
    certAPiURL: `${baseURL}/user/api/v1/abha/certPubKey`,
    getAllStateEndPoint: `${baseURL}/user/api/v1/abha/M1/registration/getAllState`,
    getAllDisctrictThroughState: `${baseURL}/user/api/v1/abha/M1/registration/getDistrict`,
    validateDocEndPoint: `${baseURL}/user/api/v1/abha/M1/registration/otherDocument/validateDocument`,
    otherCreateAbha: `${baseURL}/user/api/v1/abha/M1/registration/otherDocument/create`,

    createSession: `${baseURL}/user/api/v1/abha/M1/registration/create/session`,
    generateOtp: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/generateOtp`,
    verifyOtp: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/verifyOtp`,
    resendOtp: `${baseURL}/user/api/v1/abha/M1/registration/resend/aadhaarOtp`,
    checkNumberLinked: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/check/mobile/linked`,
    verifynewmobilelink: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/check/verify/new/mobile/linked`,
    createHealthId: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/createhealthid`,
    // abha retrival
    aadhaarGenOtpForgot: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/forgot/healthid/generate/otp`,
    aadhaarVerifyOtpForgot: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/forgot/healthid/verify/otp`,
    mobileGenOtpForgot: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/forgot/mobile/generate/otp`,
    mobileVerifyOtpForgot: `${baseURL}/user/api/v1/abha/M1/registration/aadhaar/forgot/mobile/verify/otp`,

    // abha card image
    getCard: `https://healthidsbx.abdm.gov.in/api/v1/account/getPngCard`
    // http://localhost:2002/api/v1/abha/M1/registration/getDistrict?stateCode=33
  },
  tataOneMg: `${authURL}/user/generatehash`,
  tataOneMgStoreClick: `${authURL}/user/store/click`,
  sendHayStackData: `${authURL}/user/saveDetailsHaystack`,
  
  fellowTravellers:{
    REGISTER_NEW_USER:`${authURL}/user/register/patient`
  }
  // sendHayStackData: `http://localhost:4000/user/api/v1/user/saveDetailsHaystack`,
};
