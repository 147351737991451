import React, { createContext, useContext, useEffect, useState } from "react";
import { Toast } from "service/helpers/toast";
import { getToken } from "service/helpers/utilities";
import {
  AddtoCartAPi,
  GetAllCartProductApi,
  UpdateCart,
} from "service/Medicine";

const cartContext = createContext();

export function ProvideCart({ children }) {
  const cart = useProvideCart();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
}

export const useCart = () => {
  return useContext(cartContext);
};

function useProvideCart() {
  const [cartProducts, setCartProducts] = useState({});
  const [cartProductsPrescribe, setCartProductsPrescribe] = useState([]);
  const [cartProductsNotPrescribe, setCartProductsNotPrescribe] = useState([]);
  const [cartLoader, setCartLoader] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");

  useEffect(() => {
    if (getToken() !== null) {
      GetUserCartProduct();
    }
  }, [getToken()]);

  const GetUserCartProduct = async () => {
    // setCartLoader(true);
    try {
      let { data: { data = {} } = {}, status } = await GetAllCartProductApi();
      console.log(data ,"GetUserCartProduct")
      if (status == 200) {
        setCartProducts(data);
        setAppliedCoupon(data?.couponCode);
        let prescribed = data?.items.filter((x) => x.isPrescribed);
        let notPrescribed = data?.items.filter((x) => !x.isPrescribed);
        setCartProductsNotPrescribe(notPrescribed);
        setCartProductsPrescribe(prescribed);
        // setCartLoader(false);
      } else {
        setCartLoader(false);
      }
    } catch (err) {
      setCartLoader(false);
    }
  };

  const UpdateCartQuantity = async (obj) => {
    try {
      let { data = {}, status } = await UpdateCart(obj);

      if (status == 200) {
        // if (messagetext) {
        //   Toast({
        //     type: "success",
        //     message: messagetext,
        //   });
        // }
        await GetUserCartProduct();
        return true;
      }
    } catch (err) {}
  };

  const addToCart = async (body) => {
    try {
      let { data, status } = await AddtoCartAPi(body);
      if (status === 200) {
        let prescribed = data?.data?.items?.filter((x) => x.isPrescribed);
        let notPrescribed = data?.data?.items?.filter((x) => !x.isPrescribed);
        setCartProductsNotPrescribe(notPrescribed);
        setCartProductsPrescribe(prescribed);
        await GetUserCartProduct();
        Toast({
          type: "success",
          message: data?.message,
        });
      }
    } catch (err) {
      Toast({ type: "error", message: err?.response?.data?.data?.message });
    }
  };
  return {
    cartProducts,
    setCartProducts,
    GetUserCartProduct,
    UpdateCartQuantity,
    cartProductsPrescribe,
    cartProductsNotPrescribe,
    addToCart,
    cartLoader,
    appliedCoupon,
    setAppliedCoupon,
  };
}
